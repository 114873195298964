import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import ContactMeForm from './ContactMeForm';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer'
import Servicios from './Servicios';
import Productos2 from './Productos2';
import Galería from './Galería';
import {NavLink} from "react-router-dom";

function NavBar_Links(){
  return(
    <div className='navbar-links'>
      <div className='navbar-button'>
        <NavLink to='/' className={({isActive}) => isActive ? 'navlink-active' : 'navlink-pending'} style={{ color: 'inherit'}}><FontAwesomeIcon icon={faHouse} /></NavLink>
      </div>
      <div className='navbar-button'>
        <NavLink to='/servicios' className={({isActive}) => isActive ? 'navlink-active' : 'navlink-pending'} style={{color: 'inherit', textDecoration: 'inherit'}}>
          Servicios
        </NavLink>
      </div>
      <div className='navbar-button'>
        <NavLink to='/productos' className={({isActive}) => isActive ? 'navlink-active' : 'navlink-pending'} style={{color: 'inherit', textDecoration: 'inherit'}}>
          Productos
        </NavLink>
      </div>
      <div className='navbar-button'>
        <NavLink to='galería' className={({isActive}) => isActive ? 'navlink-active' : 'navlink-pending'} style={{ color: 'inherit', textDecoration: 'inherit' }}>
          Galería
        </NavLink>
      </div>
    </div>
  )
}

class NavBar extends React.Component {
  render() {
    return (
      <div className='navbar'>
        <div className='navbar-title'>
            <h3>Alejandra's Stetic</h3>
        </div>
        <NavBar_Links/>
      </div>
    );
  }
}

class Intro extends React.Component {
  render() {
    return (
      <div className='flex-container'> 
        <div className='social-media'>
          <div className='icons'>
            <a href='https://api.whatsapp.com/send?phone=50259690471'>
              <FontAwesomeIcon className='fa-globe' icon={faWhatsapp} />
            </a>
          </div>
          <div className='icons'>
              <a href='https://instagram.com'>
                <FontAwesomeIcon className='fa-globe' icon={faInstagram} />
              </a>
          </div>
          <div className='icons'>
            <a href='https://www.facebook.com/profile.php?id=100088365317931'>
              <FontAwesomeIcon className='fa-globe' icon={faFacebook} />
            </a>
          </div>
          <p className='conectate'>Síguenos!</p>
        </div>
        <div className='picture-gallery-loop'>
          <img className="rounded-corners" src="intro_pic_1.jpg"/>
        </div>
        <div className='empty'></div>
      </div>
    );
  }
}

function Arrow(props)
{
  return (
    <div className='arrow-symbols' onClick={props.onClick}>{props.arrow}</div>
  )
}

function Product(props) {

}

class Products extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      product_index: 0
    }
  }

  handleClick(left, product_array_size)
  {
    const product = document.querySelector(".product");
    product.classList.toggle("product-hidden")
    
    setTimeout(() => {
      let index = this.state.product_index
      if (left)
      {
        index--
      }
      else
        index = (index + 1) % product_array_size
      if(index < 0)
        index = product_array_size - 1
      this.setState({
        product_index: index
      });
      product.classList.toggle("product-hidden");
    }, "250");
    
    
  }

  renderArrow(left_direction, size)
  {
    console.log("here")
    return (
      <Arrow
        arrow = {left_direction ? '«' : '»'}
        onClick={() => this.handleClick(left_direction, size)}
      />
    );

  }

  render() {
    const products = ["product_1.jpg", "product_2.jpg", "product_3.jpg", "product_4.jpg"]
    return (
      <div className='products'>
        <div id='left-arrow' className='left_scroll_arrows'>{this.renderArrow(true, products.length)}</div>
        <div className='product'><img src={products[this.state.product_index]}/></div>
        <div id='right-arrow' className='right_scroll_arrows'>{this.renderArrow(false, products.length)}</div>
      </div>
    )
  }
}

function Service(props) {
  if(props.clicked)
  {
    return (
      <div className='service-item-block'>
        <button className='service-button' onClick={props.onClick}>
          <div className='service-name'>{props.name}</div>
          <div className='arrow'>{props.arrow}</div>
        </button>
        <div className='service-description'>{props.description}</div>
        <div className='line'></div>
      </div>
    );
  }
  else
  {
    return (
      <div className='service-item-block'>
        <button className='service-button' onClick={props.onClick}>
          <div className='service-name'>{props.name}</div>
          <div className='arrow'>{props.arrow}</div>
        </button>
        <div className='line'></div>
      </div>
    );
  }
 
}

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: Array(7).fill(false),
      arrows: Array(7).fill('﹀')
    };
  }

  handleClick(i) {
    const arrows = this.state.arrows.slice();
    const clicked = this.state.clicked.slice();
    clicked[i] = !clicked[i]
    arrows[i] = clicked[i] ? '︿' : '﹀';
    this.setState({
      arrows: arrows,
      clicked: clicked
    });
  }

  renderService(i, serviceName, serviceDescription) {
    return (
      <Service
        name={serviceName}
        arrow = {this.state.arrows[i]}
        description = {serviceDescription}
        onClick={() => this.handleClick(i)}
        clicked = {this.state.clicked[i]}
      />
    );
  }

  render() {
    const service_names = ["Lifting de Pestañas", "Rizado de Pestañas", "Laminado de Cejas", "Diseño de Cejas", "Depilado de Cejas", "Diseño de Pestañas", "Botox de Pestañas y Cejas"]
    const service_desc = [
      "Ofrecemos lifting de pestañas, que es un tratamiento para alargar y crea una ligera curva hacia arriba de manera natural y duradera, consiguiendo mayor longitud y espesor. Para aquellas que tengan las pestañas medias o largas, rectas o sin forma, y deseen conseguir un resultado natural, ¡es la opción perfecta!", 
      "El rizado de pestañas es una opción ampliamente utilizada con el cual se busca curvar las pestañas por medio de unos rulos especiales que tienen diferentes tipos de grosores. Se utiliza un gel fijador que logra una larga duración del efecto obtenido.", 
      "El laminado de cejas es un tratamiento especialmente indicado para cejas onduladas y/o rebeldes. Consiste en la aplicación de producto específico en toda la extensión de las cejas para alisarlas y peinarlas de forma permanente.",
      "Mejora la forma y simetría de las cejas, Define y suaviza rasgos, y resalta, rejuvenece y levanta la mirada.",
      "Se eliminan los pelos desde la raíz sin agredir o manchar la piel. El efecto dura entre 1-2 semanas hasta que vuelva a crecer el vello natural.",
      "Siempre al margen en las actualizaciones de pestañas pelo a pelo, contamos con: efecto clásico, efecto rimel, efecto híbrido japonés, híbrido hawaino, efecto egipci0, volumen, y mega volumen, ¡Así que contamos con el diseño adecuado para ti!",
      ""]
    
    return (
      <div className='services'>
        <div><h1>Servicios</h1></div>
        <p className='services-intro-txt'>         
          Aqui en Alenjandra's Stetic, le brindamos servicios que mejoran la autoestima tanto de mujeres como de hombres. Confía en nosotros para ayudarte a sentirte mejor y mostrar tu mejor cara con nuestros servicios excepcionales.
        </p>
        <div className='services-container'>
          <div className='service-intro'>
            <div className='service-intro-img'>
              <img src="beauty_1_cameo.svg"/>
            </div>
          </div>
          <div className='service-item-container'>
            <div className='service-item'>{this.renderService(0, service_names[0], service_desc[0])}</div>
            <div className='service-item'>{this.renderService(1, service_names[1], service_desc[1])}</div>
            <div className='service-item'>{this.renderService(2, service_names[2], service_desc[2])}</div>
            <div className='service-item'>{this.renderService(3, service_names[3], service_desc[3])}</div>
            <div className='service-item'>{this.renderService(4, service_names[4], service_desc[4])}</div>
            <div className='service-item'>{this.renderService(5, service_names[5], service_desc[5])}</div>
            <div className='service-item'>{this.renderService(6, service_names[6], service_desc[6])}</div>
          </div>
          </div>
      </div> 
    )
  }
}

class Header extends React.Component {
  render() {
    return (
      <div className='title'>
        <p>Alejandra's Stetic</p>
      </div>
    );
  }
}

function Intro2(props)
{
  return (
    <div className='flex-container-ii'>
      <div className='showcase-container'>
        <div className='showcase-item' id='showcase-item-1'>
          <div className='showcase-img'>
            <img src="customer_1.jpg"/>
          </div>
          <div className='showcase-txt'>
            <h3>Pestañas unicas como tu</h3>
          </div>
        </div>
        <div className='showcase-item'>
          <div className='showcase-txt'>
            <h3>Elije las pestañas que le quedan a tu estilo</h3>
          </div>
          <div className='showcase-img'>
            <img src="customer_2.jpg"/>
          </div>
        </div>
        <div className='showcase-item' id='showcase-item-3'>
          <div className='showcase-img'>
            <img src="customer_3.jpg"/>
          </div>
          <div className='showcase-txt'>
            <h3>El cambio que deseas</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

function Productos(props){
  return (
    <div className='services'>
    <div><h1>Productos</h1></div>
    <p className='services-intro-txt'>         
      Ofrecemos una variedad de productos para el pelo, la cara, y la piel. 
    </p>
    <Products/>
  </div>
  ) 
}

function Gallery(props){
  return (
    <div className='services'>
    <div><h1>Galería</h1></div>
    <p className='services-intro-txt'>         
      Trabajos realizados.
    </p>
    <Slideshow/>
  </div>
  ) 
}

function ContactMe(props){
  return (
    <div className='services'>
    <div><h1>Contáctame</h1></div>
    <p className='services-intro-txt'>         
      Conéctese con nosotros a través de las redes sociales o use el siguiente formulario para escribirnos.
    </p>
    <div className='social-media-ii'>
      <div className='icons-ii'>
        <a href='https://api.whatsapp.com/send?phone=50259690471'>
          <FontAwesomeIcon className='fa-globe' icon={faWhatsapp} />
        </a>
      </div>
      <div className='icons-ii'>
          <a href='https://instagram.com'>
            <FontAwesomeIcon className='fa-globe' icon={faInstagram} />
          </a>
      </div>
      <div className='icons-ii'>
        <a href='https://www.facebook.com/profile.php?id=100088365317931'>
          <FontAwesomeIcon className='fa-globe' icon={faFacebook} />
        </a>
      </div>
    </div>
    <ContactMeForm/>
  </div>
  )
}

class Slideshow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      product_index: 0
    }
  }

  handleClick(left, product_array_size)
  {
    const product = document.querySelector(".product");
    product.classList.toggle("product-hidden")
    
    setTimeout(() => {
      let index = this.state.product_index
      if (left)
      {
        index--
      }
      else
        index = (index + 1) % product_array_size
      if(index < 0)
        index = product_array_size - 1
      this.setState({
        product_index: index
      });
      product.classList.toggle("product-hidden");
    }, "250");
    
    
  }

  renderArrow(left_direction, size)
  {
    return (
      <Arrow
        arrow = {left_direction ? '«' : '»'}
        onClick={() => this.handleClick(left_direction, size)}
      />
    );

  }

  render() {
    const products = ["customer_1.jpg", "customer_2.jpg", "customer_4.jpg", "customer_5.jpg", "customer_6.jpg", "customer_7.jpg"]
    return (
      <div className='products'>
        <div id='left-arrow' className='left_scroll_arrows'>{this.renderArrow(true, products.length)}</div>
        <div className='product'><img src={products[this.state.product_index]}/></div>
        <div id='right-arrow' className='right_scroll_arrows'>{this.renderArrow(false, products.length)}</div>
      </div>
    )
  }
}

class Page extends React.Component {
  render() {
    return (
      <Router>
        <div className='page-container'>
            <NavBar/>
          <Routes>
            <Route path='/' element={
              <div>
                <Intro/>
                <Intro2/>
                <Services/>
                <Productos/>
                <ContactMe/>
              </div>
            }/>
            <Route path='/servicios' element={<Services/>} />
            <Route path='/productos' element={<Productos/>} />
            <Route path='/galería' element={<Gallery/>} />
          </Routes>
            <Footer/>
        </div>
      </Router>
    );
  }
}

export default NavBar_Links;

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Page />);
